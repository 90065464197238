import Vue from 'vue'
import VueRouter from 'vue-router'
import TimeSheet from '@/views/TimeSheet.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'TimeSheet',
    component: TimeSheet
  },
  {
    path: '/timeTracker',
    name: 'TimeTracker',
    component: () => import('../views/TimeTracker.vue')
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('../views/Calendar.vue')
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import('../views/Clients.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/Projects.vue')
  },
  {
    path: '/project/:id',
    name: 'ProjectItem',
    component: () => import('../views/ProjectItem.vue'),
    props: true
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/Reports.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/tags',
    name: 'Tags',
    component: () => import('../views/Tags.vue')
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import('../views/Team.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
