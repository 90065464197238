<template>
    <div class="workspaces-modal" v-click-outside="onClickOutside">
        <div class="workspaces-modal__info">
            <p class="workspaces-modal__title">Workspaces</p>
            <button v-if="showManageWorkspaces" class="workspaces-modal__manage">Manage</button>
        </div>
        <ul
            v-for="item in workspaceStore.workspaces"
            :key="item.id">
            <li class="workspace-li-el" :class="{ active: workspaceStore.currentWorkspace.uk == item.uk }" @click="selectWorkspace(item)">{{ item.t }}</li>
        </ul>
        <svg class="double-slash" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6667 7.66699L7.66669 13.667" stroke="#BABCBF" stroke-width="2" stroke-linecap="round"/>
            <path d="M13.6667 1.66699L1.66669 13.667" stroke="#BABCBF" stroke-width="2" stroke-linecap="round"/>
        </svg>
    </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import { useWorkspaceStore } from '@/store/WorkspaceStore'
 
  export default {
    props: ['changeWorkspace'],
    data() {
        const workspaceStore = useWorkspaceStore()
        return {
            workspaceStore: workspaceStore,
            showManageWorkspaces: false
        }
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    methods: {
        selectWorkspace(item){
            this.workspaceStore.setCurrentWorkspace(item)
            this.changeWorkspace()
            this.$emit('close')
        },
        onClickOutside () {
            this.$emit('close')
        }
    }
  };
</script>

<style scoped>
    .workspace-li-el {
        cursor: pointer;
    }
    .workspaces-modal{
        position: absolute;
        width: 330px;
        height: auto;
        background: #fff;
        border: 1px solid #F6F7F9;
        box-sizing: border-box;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
        right: 60px;
        top: 75px;
        padding: 10px 15px;
        z-index: 111;
    }

    .workspaces-modal__info{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .workspaces-modal__title{
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
        color: #BABCBF;
        padding: 0;
        margin: 0;
    }

    .workspaces-modal__manage{
        border: none;
        text-decoration: underline;
        background-color: transparent;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: #58C7E0;
    }

    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        padding-top: 5px;
    }

    li{
        position: relative;
        padding: 5px 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        width: 100%;
    }

    li.active:after{
        position: absolute;
        content: 'Active';
        color: #37BB5C;
        background-image: url('../../assets/img/active.png');
        background-repeat: no-repeat;
        background-position: right;
        width: 68px;
        height: 18px;
        right: 5px;
    }

    .double-slash{
        position: absolute;
        right: 3px;
        bottom: 3px;
        cursor: pointer;
    }

    @media(max-width: 480px){
        .workspaces-modal{
            z-index: 111;
            right: 0px;
            top: 132px;
        }
    }

    @media(max-width: 330px){
        .workspaces-modal{
            width: 100%;
        }
    }
</style>