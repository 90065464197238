<template>
    <div class="calendar-modal" v-click-outside="onClickOutside">
        <DatePicker v-model="currentDate" is-range color="teal"/>
    </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import vClickOutside from 'v-click-outside'

    export default {
        props: ['updateMethod', 'startDate', 'endDate'],
        components: {
            DatePicker
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        methods: {
            onClickOutside () {
                this.$emit('close')
            }
        },
        watch: {
            currentDate(){
                if ( this.currentDate.end.getTime()  > this.currentDate.start.getTime() + 7 * 24 * 3600) {
                    this.currentDate.end = new Date(this.currentDate.start)
                    this.currentDate.end = new Date(this.currentDate.end.setDate(this.currentDate.end.getDate() + 6))
                }
                this.updateMethod(this.currentDate)
                this.$emit('close')
            }
        },
        data(){
            return{
                currentDate: { start: this.startDate, end: this.endDate }
            }
        }
    }
</script>

<style scoped>

    .item__wrp-icons .calendar-modal,
    .tracker-info__tab .calendar-modal{
        width: 255px;
        right: 275px;
        top: 75px;
    }

    .item__wrp-icons .calendar-modal{
        top: 60px;
    }

    .top-modal .calendar-modal{
        right: 0;
        top: 50px;
        width: 255px;
        background: #fff;
        left: 540px;
    }


    .calendar-modal{
        position: absolute;
        width: 253px;
        height: 270px;
        background: #fff;
        border: 1px solid #F6F7F9;
        box-sizing: border-box;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
        right: 30px;
        top: 150px;
        z-index: 10;
    }

    .vc-container{
        border: none;
    }

    @media(max-width: 560px){
        .calendar-modal{
            width: 100%;
            right: 0;
            overflow-x: auto;
        }
    }
    
    @media(max-width: 1403px){

        .second .calendar-modal{
            top: 155px;
        }
    }

    @media(max-width:1199px){
        .second .item__wrp-icons .calendar-modal{
            top: 155px;
        }
    }

    @media(max-width:999px){
        .second .calendar-modal{
            top: 240px;
            right: 277px;
        }

        .close .second .calendar-modal{
            top: 190px;
        }
    }

    @media(max-width: 944px){
        .tracker-wrp__item .calendar-modal{
            right: 40px;
        }

        .close .tracker-wrp__item .calendar-modal{
            right: 250px;
        }
    }

    @media(max-width:800px){
        .close .second .calendar-modal{
            top: 240px;
        }
    }

    
    @media(max-width: 744px){
        .tracker-wrp__item .calendar-modal{
            right: 35px;
        }

        .close .tracker-wrp__item .calendar-modal{
            right: 35px;
        }
    }

    @media(max-width:710px){
        .first .calendar-modal{
            right: 0;
            left: 0;
        }
    }

    @media(max-width: 615px){
        .close .second .calendar-modal{
            top: 320px;
            right: 240px;
        }


        .tracker-wrp__item .calendar-modal{
            right: 20px;
        }

        .close .tracker-wrp__item .calendar-modal{
            right: 20px;
        }
    }

    @media(max-width: 591px){
        .close .second .calendar-modal{
            top: 264px;
            right: 100px;
        }

    }

    @media(max-width:480px){
        .close .second .calendar-modal{
            right: 20px;
        }

        .tracker-wrp__item .calendar-modal{
            right: 0;
            top: 105px;
        }

        .close .tracker-wrp__item .calendar-modal{
            right: 0;
            top: 105px;
        }
    }

    @media(max-width:391px){
        .close .second .calendar-modal{
            top: 293px;
            right: 10px;
        }

    }

    @media(max-width:350px){
        .close .second .calendar-modal{
            top: 273px;
            right: 0;
            width: 100%;
        }

        .close .tracker-wrp__item .calendar-modal{
            right: 0;
            width: 100%;
        }
    }


</style>