import { defineStore } from 'pinia'
import Vue from 'vue'
import { ApiCall } from '@/store/ApiCall'

export const useTimesheetStore = defineStore({
    id: 'TimesheetStore',
    state(){ 
        const today = new Date()

        let firstDay = new Date(today.setDate(today.getDate() - today.getDay()))
        let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 6))

        console.log(firstDay + ' ' + lastDay)
        return { 
            list: [],
            errors: [],
            startDate: firstDay,
            endDate: lastDay,
            startDateStr: firstDay.getFullYear()+'-'+("0" + (firstDay.getMonth() + 1)).slice(-2) + '-' + ("0" + firstDay.getDate()).slice(-2),
            endDateStr: lastDay.getFullYear()+'-'+("0" + (lastDay.getMonth() + 1)).slice(-2) + '-' + ("0" + lastDay.getDate()).slice(-2)
        }
    },
    getters:{
        count: function(state){
            return state.list.length
        },
        getDates: function(state){
            return {start: state.startDateStr, end: state.endDateStr}
        },
        timesheetData: function(state) {
            let startDate = new Date(this.startDate)
            let dates = []
            let datesTotal = []
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            for(let d = 0; d < 7; d++){
                let currentDate = new Date(startDate.setDate(startDate.getDate() + (d==0?0:1)))
                let currentDateStr  = new Date(currentDate).toLocaleDateString('en-us', options)
                dates.push(currentDateStr)
                datesTotal[d] = {total: '00:00:00', date: currentDateStr}
            }
            if ( state.list.length == 0) return { entries: [], dates: dates }

            let tasks = new Object()
            let taskEntries = new Object()
            startDate = new Date(this.startDate)
            
            for(let i = 0; i < state.list.length; i++){
                if ( tasks[state.list[i]['tuk']] == null)
                    tasks[state.list[i]['tuk']] = state.list[i]
                let timeEntryKey = state.list[i]['tuk'] + '_'+ state.list[i]['sd']
                if ( taskEntries[timeEntryKey] == null)
                    taskEntries[timeEntryKey] = '00:00:00'
                taskEntries[timeEntryKey] = this.timeSum(taskEntries[timeEntryKey], state.list[i]['tt'])
            }
            let entries = []
            let taskUks = Object.keys(tasks)

            for( let t = 0; t < taskUks.length; t++){
                let taskUk = taskUks[t]
                let taskTime = { dates: [], task: tasks[taskUk], total_time: '00:00:00'}
                for(let d = 0; d < 7; d++){
                    let currentDate = new Date(startDate.setDate(startDate.getDate() +  (d==0?0:1)))
                    let currentDateStr  = currentDate.getFullYear()+'-'+("0" + (currentDate.getMonth() + 1)).slice(-2) + '-' + ("0" + currentDate.getDate()).slice(-2)
                    if ( taskEntries[taskUk+'_'+currentDateStr] != null ) taskTime.dates.push({ time: taskEntries[taskUk+'_'+currentDateStr], date: currentDateStr })
                    else taskTime.dates.push({ time: '00:00:00', date: currentDateStr })
                    taskTime.total_time = this.timeSum(taskTime.total_time, taskTime.dates[d].time)
                    datesTotal[d].total = this.timeSum(datesTotal[d].total, taskTime.dates[d].time)
                }
                entries.push(taskTime)
            }
            let overallTotal = '00:00:00'
            for(let d = 0; d < 7; d++){
                overallTotal = this.timeSum(datesTotal[d].total, overallTotal)
            }
            console.log('TimeSheet entry length: ' +  entries.length)
            console.log('TimeSheet dates length: ' +  dates.length)
            return { entries: entries, dates: dates, datesTotal: datesTotal, overallTotal: overallTotal }
        },
        searchValue: function(state){
            return state.search
        }
    },
    actions:{
        timeSum(time1, time2) {
            let time1arr = time1.split(':')
            let time2arr = time2.split(':')

            let seconds = parseInt(time1arr[2]) + parseInt(time2arr[2])

            let minutes = parseInt(time1arr[1]) + parseInt(time2arr[1]) + Math.floor(seconds / 60)
            seconds = seconds % 60

            let hours = parseInt(time1arr[0]) + parseInt(time2arr[0]) + Math.floor(minutes / 60)
            minutes = minutes % 60
            
            return ("0" + hours).slice(-2) + ':' + ("0" + minutes).slice(-2) + ':' + ("0" + seconds).slice(-2)
        },
        async changeDates(start, end){
            this.$patch((state) => {
                state.startDate = start
                state.endDate = end
                state.startDateStr = state.startDate.getFullYear()+'-'+("0" + (state.startDate.getMonth() + 1)).slice(-2) + '-' + ("0" + state.startDate.getDate()).slice(-2)
                state.endDateStr = state.endDate.getFullYear()+'-'+("0" + (state.endDate.getMonth() + 1)).slice(-2) + '-' + ("0" + state.endDate.getDate()).slice(-2)
            })
            await this.listing()
        },
        async listing(){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText.toLowerCase() == 'ok') {
                try  {
                    let dates = this.getDates
                    const response = await ApiCall.post(
                        'ws/{wk}/entries/timesheet',
                        {start_date: dates.start, end_date: dates.end }
                    );
                
                    if ( response.data.result=='ok') {
                        this.$patch((state) => {
                            state.list = response.data.message
                        })
                    }
                    else {
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    }
                }
                catch (error) {
                    this.$patch((state) => {
                        state.errors.push(error);
                    })
                }
            } else {
                this.$patch((state) => {
                    state.errors.push(optionsData.message)
                })
            }
        },
        async update(uk, form){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText == 'OK') {
                const response = await ApiCall.post(
                    'ws/{wk}/entries/timesheet/' + uk,
                    form
                )
                this.$patch((state) => {
                    for(let i=0;i<this.list.length;i++){
                        if ( state.list[i].uk == uk) {
                            state.list.splice(i, 1)
                            state.list.unshift(response.data.message)
                            break
                        }
                    }   
                })
                return response
            }
        },
        async remove(date, taskUk) {
            ApiCall.options().then(response => {
                ApiCall.delete('ws/{wk}/entries/timesheet/' + date + '/' + taskUk)
                .then(response => {
                    if ( response.data.result!='ok')
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    else {
                        this.$patch((state) => {
                            for(let i=0;i<this.list.length;i++){
                                if ( state.list[i].tuk == taskUk) {
                                    state.list.splice(i, 1)
                                    break
                                }
                            }   
                        })
                    }
                })
                .catch(e => {
                    this.errors.push(e)
                })
            })
            .catch(e => {    
                this.$patch((state) => {
                    state.errors.push(e);
                })
            }) 
        }
    }
})