import axios from 'axios'
import Vue from 'vue'

export const ApiCall = {
    workspaceUk: '',
    setWorkspaceUk(uk){
        ApiCall.workspaceUk = uk
    },
    async options(skipCheck = false){
        if ( this.workspaceUk == '' && !skipCheck) return { statusText: 'no_ws_uk'}
        let response = await axios({
            method: 'OPTIONS',
            url: Vue.config.remoteApiHost + 'ws/options' + this.getTokenStr()
        })
        return response
    },
    async get(url) {
        if ( url.indexOf('{wk}') != -1 && this.workspaceUk == '') return 
        return await axios.get(
            Vue.config.remoteApiHost + url.replace('{wk}', this.workspaceUk) + this.getTokenStr() + this.getUukStr()
        )
    },
    async post(url, form){
        if ( url.indexOf('{wk}') != -1 && this.workspaceUk == '') return 
        return await axios.post(
            Vue.config.remoteApiHost + url.replace('{wk}', this.workspaceUk) + this.getTokenStr() + this.getUukStr(), form
        )
    },
    async put(url, form){
        if ( url.indexOf('{wk}') != -1 && this.workspaceUk == '') return 
        return await axios({
            method: "PUT",
            url: Vue.config.remoteApiHost + url.replace('{wk}', this.workspaceUk)+ this.getTokenStr() + this.getUukStr(),
            data: form
        })
    },
    async delete(url) {
        if ( url.indexOf('{wk}') != -1 && this.workspaceUk == '') return 
        return await axios.delete(
            Vue.config.remoteApiHost + url.replace('{wk}', this.workspaceUk) + this.getTokenStr() + this.getUukStr()
        ) 
    },
    
    getTokenStr(){
        let csrfToken = ''
        let cookieValue = `; ${document.cookie}`
        let parts = cookieValue.split(`; trf-token=`)
        if (parts.length === 2) {
            csrfToken = parts.pop().split(';').shift();
        }
        if ( csrfToken.trim() == '') 
            location.href = Vue.config.authPage
        return '?trf-token='+csrfToken
    },
    
    getUukStr(){
        let uToken = ''
        let cookieValue = `; ${document.cookie}`
        let parts = cookieValue.split(`; u_uk=`)
        if (parts.length === 2) {
            uToken = parts.pop().split(';').shift();
        }
        if ( uToken.trim() == '') 
            location.href = Vue.config.authPage
        return '&u='+uToken
    },
}