<template>
  <div>
    <TimeSheetInfo />
    <TimeSheetWrp />
    <TimeSheetAddRow v-if="showAddNewRow" />
  </div>
</template>

<script>
    import TimeSheetInfo from '@/components/TimeSheet/TimeSheetInfo'
    import TimeSheetWrp from '@/components/TimeSheet/TimeSheetWrp'
    import TimeSheetAddRow from '@/components/TimeSheet/TimeSheetAddRow'

    export default {
        data() {
          return {
            showAddNewRow: false
          }
        },
        components: {
          TimeSheetInfo, TimeSheetWrp, TimeSheetAddRow,
        }
    }
</script>
