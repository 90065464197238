<template>
    <section>
        <table>
            <thead>
                <tr>
                    <th>Projects</th>
                    <th v-for="date of timesheetStore.timesheetData.dates" :key="date">{{ date }}</th> 
                    <th>Total</th>     
                    <th></th>                 
                </tr>
            </thead>
            <tbody>
                <tr v-for="entry of timesheetStore.timesheetData.entries" :key="entry.task.uk">
                    <td>
                        <span>{{ entry.task.t }}</span>
                    </td>
                    <td v-for="date of entry.dates" :key="date.date">
                        <input v-model="date.time" type="text" placeholder="00:00:00" pattern="\b[0-2]?\d:[0-5]\d\b:[0-5]\d\b">
                    </td>
                    <td>
                        <p @click="calculationTotal">
                            {{ entry.total_time }}
                        </p>
                    </td>
                    <td @click="clearAll">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.33331 14.6667L7.99998 8.00001M14.6666 1.33334L7.99998 8.00001M7.99998 8.00001L14.6666 14.6667M7.99998 8.00001L1.33331 1.33334" stroke="#F2F3F6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </td>
                </tr>
                <TimeSheetWrpTotal :datesTotal="timesheetStore.timesheetData.datesTotal" />
            </tbody>
        </table>
    </section>
</template>

<script>
    import TimeSheetWrpTotal from '@/components/TimeSheet/TimeSheetWrpTotal'
    import { useTimesheetStore } from '@/store/TimesheetStore'
    import { EventBus } from '@/store/EventBus'


    export default {
        data() {
            const timesheetStore = useTimesheetStore()
            return {
                timesheetStore: timesheetStore
            } 
        },
        methods:{
            calculationTotal(){

            },
            clearAll(){

            }
        },
        created(){
            this.timesheetStore.listing()
        },
        components: {
            TimeSheetWrpTotal
        }
    }

    const wlTimeSheetHandler = function() {
        const timesheetStore = useTimesheetStore()
        timesheetStore.listing()
        EventBus.$off('click', wlTimeSheetHandler)
    }

    EventBus.$on('click', wlTimeSheetHandler)
    
</script>


<style scoped>
    th{
        padding: 0px 20px 0px 0px;
    }
    section{
        background: #FFFFFF;
        margin: 0 30px;
        border: 1px solid #F2F3F6;
        box-sizing: border-box;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
        min-width: 200px;
        overflow: auto;
    }

    table{
        width: 100%;
        min-width: 1140px;
    }

    th{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        width: 110px;
        color: #606569;
    }

    th:first-child{
        width: 32%;
        text-align: left;
        padding-left: 25px;
        color: #222222;
    }

    th:nth-last-child(2){
        color: #222222;
    }

    tr{
        height: 58px;
    }

    @media(max-width:560px){
        section{
            margin: 0 15px;
        }
    }

    p,
    input{
        font-size: 14px;
        font-weight: 500;
        border-radius:30px;
        border: 2px solid #F2F3F6;
        width: 110px;
        height: 42px;
        text-align: center;
        line-height: 38px;
        margin: 0;
        box-sizing: border-box;
        cursor: pointer;
        font-family: Montserrat;
        outline: none;
    }

    input:invalid{
        border: 1px solid red;
    }

    td{
        height: 70px;
        border-top: 1px solid #F2F3F6;
    }

    .large-padding td{
        height: 60px;
    }

    td:first-child{
        text-align: left;
        padding-left: 45px;
        color: #222222;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }

    span{
        position: relative;
    }

    span:before{
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        border-radius:50%;
        background-color: #323DA3;
        top: 5px;
        left: -20px;
    }

    td:last-child{
        display: flex;
        flex-direction: row;
        cursor: pointer;
        min-width: 40px;
    }

    td:nth-last-child(2) p{
        border: none;
        font-weight: 600;
        margin: 0;
    }

    td:last-child svg{
        align-self: center;
    }

</style>