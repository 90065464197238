<template>
    <div class="notifications-modal" v-click-outside="onClickOutside">
        <div class="notifications-modal-inherit">
            <div class="notifications-modal__info">
                <p class="notifications-modal__title">Notifications</p>
                <button class="notifications-modal__clear">Clear all</button>
            </div>
            <div class="notifications-modal__wrp">
                <div class="notifications-modal__item item">
                    <a href="" class="item__close-btn">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.66669 12.3333L7.00002 7M12.3334 1.66667L7.00002 7M7.00002 7L12.3334 12.3333M7.00002 7L1.66669 1.66667" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                    <h4 class="item__title">
                        NEW: QuickBooks integration
                    </h4>
                    <p class="item__txt">
                        Sync users and customers, and send time to QuickBooks.
                    </p>
                    <button class="notifications-modal__clear item__clear">Clear all</button>
                </div>
                            <div class="notifications-modal__item item">
                    <a href="" class="item__close-btn">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.66669 12.3333L7.00002 7M12.3334 1.66667L7.00002 7M7.00002 7L12.3334 12.3333M7.00002 7L1.66669 1.66667" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                    <h4 class="item__title">
                        NEW: QuickBooks integration
                    </h4>
                    <p class="item__txt">
                        Sync users and customers, and send time to QuickBooks.
                    </p>
                    <button class="notifications-modal__clear item__clear">Clear all</button>
                </div>
                            <div class="notifications-modal__item item">
                    <a href="" class="item__close-btn">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.66669 12.3333L7.00002 7M12.3334 1.66667L7.00002 7M7.00002 7L12.3334 12.3333M7.00002 7L1.66669 1.66667" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                    <h4 class="item__title">
                        NEW: QuickBooks integration
                    </h4>
                    <p class="item__txt">
                        Sync users and customers, and send time to QuickBooks.
                    </p>
                    <button class="notifications-modal__clear item__clear">Clear all</button>
                </div>
                            <div class="notifications-modal__item item">
                    <a href="" class="item__close-btn">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.66669 12.3333L7.00002 7M12.3334 1.66667L7.00002 7M7.00002 7L12.3334 12.3333M7.00002 7L1.66669 1.66667" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                    <h4 class="item__title">
                        NEW: QuickBooks integration
                    </h4>
                    <p class="item__txt">
                        Sync users and customers, and send time to QuickBooks.
                    </p>
                    <button class="notifications-modal__clear item__clear">Clear all</button>
                </div>
                            <div class="notifications-modal__item item">
                    <a href="" class="item__close-btn">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.66669 12.3333L7.00002 7M12.3334 1.66667L7.00002 7M7.00002 7L12.3334 12.3333M7.00002 7L1.66669 1.66667" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                    <h4 class="item__title">
                        NEW: QuickBooks integration
                    </h4>
                    <p class="item__txt">
                        Sync users and customers, and send time to QuickBooks.
                    </p>
                    <button class="notifications-modal__clear item__clear">Clear all</button>
                </div>
                            <div class="notifications-modal__item item">
                    <a href="" class="item__close-btn">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.66669 12.3333L7.00002 7M12.3334 1.66667L7.00002 7M7.00002 7L12.3334 12.3333M7.00002 7L1.66669 1.66667" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                    <h4 class="item__title">
                        NEW: QuickBooks integration
                    </h4>
                    <p class="item__txt">
                        Sync users and customers, and send time to QuickBooks.
                    </p>
                    <button class="notifications-modal__clear item__clear">Clear all</button>
                </div>
            </div>
        </div>
        <svg class="double-slash" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6667 7.66699L7.66669 13.667" stroke="#BABCBF" stroke-width="2" stroke-linecap="round"/>
            <path d="M13.6667 1.66699L1.66669 13.667" stroke="#BABCBF" stroke-width="2" stroke-linecap="round"/>
        </svg>
    </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    
    export default {
        directives: {
        clickOutside: vClickOutside.directive
        },
        methods: {
        onClickOutside () {
            this.$emit('close')
        }
        }
    };
</script>

<style scoped>

    .notifications-modal{
        position: absolute;
        width: 340px;
        height: 275px;
        background: #fff;
        border: 1px solid #F6F7F9;
        box-sizing: border-box;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
        right: 60px;
        top: 75px;
        z-index: 111;
    }

    .notifications-modal-inherit{
        overflow-y: auto;
        padding: 10px 15px;
        width: 340px;
        height: 275px;
        box-sizing: border-box;
    }

    .notifications-modal__info{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .notifications-modal__title{
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
        color: #BABCBF;
        padding: 0;
        margin: 0;
    }

    .notifications-modal__clear{
        border: none;
        text-decoration: underline;
        background-color: transparent;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: #58C7E0;
        cursor: pointer;
    }

    .notifications-modal__wrp{
        margin-top: 5px;
    }

    .notifications-modal__item{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        margin: 5px 0;
    }

    .item__close-btn{
        position: absolute;
        top: 2px;
        right: 10px;
    }

    .item__title{
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }

    .item__txt{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        margin: 0;
        padding: 0;
        padding-top: 3px;
    }

    .item__clear{
        align-self: flex-start;
        padding: 0;
        padding-bottom: 3px;
    }
    
    .double-slash{
        position: absolute;
        right: 3px;
        bottom: 3px;
        cursor: pointer;
    }

    @media(max-width: 480px){
        .notifications-modal{
            z-index: 111;
            right: 0px;
            top: 132px;
        }
    }

    @media(max-width: 340px){
        .notifications-modal{
            width: 100%;
        }
    }
</style>