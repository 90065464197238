<template>
  <div class="container close" v-bind:class="{dark: isCheckedDark}">
    <TopMenu />
    <LeftMenu />
    <section class="dashboard large-padding">
      <router-view />
    </section>
  </div>
</template>

<script>
import TopMenu from '@/components/TopMenu'
import LeftMenu from '@/components/LeftMenu'

export default {
  data() {
    return {
      isCheckedDark: false,
    }
  },
  components: {
    TopMenu, LeftMenu
  },
}
</script>
<style>

</style>

