import { defineStore } from 'pinia'
import axios from 'axios'
import Vue from 'vue'
import { ApiCall } from '@/store/ApiCall'

export const useApiCallStore = defineStore({
    id: 'ApiCallStore',
    state: ()=>({ 
        errors: []
    }),
    getters:{
        errorList: function(state){
            return state.errors
        }
    },
    actions:{
        async apiCallAsync(callApiMethod){
            const optionsData = await ApiCall.options(true)
            if ( optionsData.statusText == 'OK') {
                return await callApiMethod()
            }
        }
    }
})