import { defineStore } from 'pinia'
import Vue from 'vue'
import { ApiCall } from '@/store/ApiCall'
import { useApiCallStore } from '@/store/ApiCallStore'

export const useProfileStore = defineStore({
    id: 'ProfileStore',
    state() { 
        const apiCallStore = useApiCallStore()
        return {
            apiCallStore: apiCallStore,
            errors: [],
            currentUser: null
        }
    },
    getters:{
        user: function(state){
            return state.currentUser
        }
    },
    actions:{
        async update(form){
            if ( !this.currentUser ) return
            const optionsData = await ApiCall.options(true)
            if ( optionsData.statusText == 'OK') {
                console.log('Update user: ' + this.currentUser.t)
                const response = await ApiCall.post(
                    'ws/users/profile',
                    form
                )
                if ( form.t ) this.currentUser.t = form.t 
                if ( form.e ) this.currentUser.e = form.e
                return response
            }
        },
        async updatePassword(form) {
            if ( !this.currentUser ) return
            const optionsData = await ApiCall.options(true)
            if ( optionsData.statusText == 'OK') {
                console.log('Update user: ' + this.currentUser.t)
                const response = await ApiCall.post(
                    'ws/users/password',
                    form
                )
                return response
            }
        },
        async getUser(){
            return await this.apiCallStore.apiCallAsync( this.getUserMethod )
        },
        async getUserMethod(){
            try  {
                const response = await ApiCall.get(
                    'ws/users/details');
                
                if ( response.data.result=='ok') {
                    this.$patch((state) => {
                        state.currentUser = response.data.message
                        console.log('Curent new workspace: ' + state.currentUser)
                    })
                }
                else {
                    this.$patch((state) => {
                        state.errors.push(response.data.message);
                    })
                }
            }
            catch (error) {
                this.$patch((state) => {
                    state.errors.push(error);
                })
            }
        },
        delete_cookie(name) {
            document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        },
        logout(){
            console.log('ProfileStore: Logout')
            this.delete_cookie('trf-token')
            this.delete_cookie('u_uk')
            location.href = Vue.config.authPage
        },
    }
})