<template>
    <tr>
        <td>
            <p>
                Total:
            </p>
        </td>
        <td v-for="date of timesheetStore.timesheetData.datesTotal" :key="date.date">
            <p>
                {{ date.total }}
            </p>
        </td>
        <td>
            <p>
               {{ timesheetStore.timesheetData.overallTotal }}
            </p>
        </td>
        <td>
        </td>
    </tr>
</template>

<script>
    import { useTimesheetStore } from '@/store/TimesheetStore';


    export default {
        data() {
            const timesheetStore = useTimesheetStore()
            return {
                timesheetStore: timesheetStore
            } 
        }
    }
    
</script>

<style scoped>
    p {
        font-size: 14px;
        font-weight: 500;
        min-width: 110px;
        height: 42px;
        text-align: center;
        line-height: 38px;
        margin: 0;
        box-sizing: border-box;
        cursor: pointer;
        padding: 0px 20px 0px 0px;
    }

    td{
        height: 70px;
        border-top: 1px solid #F2F3F6;
        color: #606569;
    }

    .large-padding td{
        height: 60px;
    }

    td:first-child p{
        text-align: left;
        padding-left: 25px;
        font-size: 16px;
        font-weight: 600;
        line-height: 42px;
        color: #222222;
    }

    span{
        position: relative;
    }

    td:last-child{
        display: flex;
        flex-direction: row;
        color: #222222;
    }

    td:nth-last-child(2) p{
        border: none;
        font-weight: 600;
        margin: 0;
    }

    td:last-child svg{
        align-self: center;
    }
</style>