<template>
    <div class="profile-modal" v-click-outside="onClickOutside">
        <ul>
            <li> <a href="#">{{ userName }}</a> </li>
            <li> <a href="#">{{ userEmail }}</a></li>
            <li>   <router-link to="/profile"> Profile settings </router-link></li>
            <li class="profile-modal__dark">
                <span>Dark theme</span>
                <input type="checkbox" name="#" id="input_dark">
                <label for="input_dark"></label>
            </li>
            <li v-if="showDownloadApps"> <a href="#"> Download apps </a></li>
            <li v-if="showChatApp"> <a href="#"> Try chat app </a></li>
            <li> <a class="clickable-button" @click="logout"> Logout </a></li>
        </ul>
    </div>
</template>


<script>
import vClickOutside from 'v-click-outside'
    import { useProfileStore } from '@/store/ProfileStore'
 
  export default {
    data(){
        const profileStore = useProfileStore()
        return {
            profileStore: profileStore,
            showDownloadApps: false,
            showChatApp: false,
            userName: profileStore.currentUser ? profileStore.currentUser.t : '',
            userEmail: profileStore.currentUser ? profileStore.currentUser.e : ''
        }
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    methods: {
        logout(){
            console.log('Logout')
            this.profileStore.logout()
        },
        onClickOutside () {
            this.$emit('close')
        }
    }
  };
</script>

<style scoped>
    .profile-modal{
        position: absolute;
        width: 250px;
        height: auto;
        background: #fff;
        border: 1px solid #F6F7F9;
        box-sizing: border-box;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
        right: 60px;
        top: 75px;
        padding: 15px;
        z-index: 111;
    }

    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        color: #222222;
    }

    li{
        padding-bottom: 6px;
    }

    li,
    a{
        text-decoration: none;
        color: #222222;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;

    }

    li:first-child a{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }

    li:first-child{
        padding: 0;
        padding-bottom: 4px;
    }

    li:nth-child(2){
        border-bottom: 2px solid #F5F6F8;
        padding: 0;
        padding-bottom: 10px;
    }

    li:nth-child(3){
        padding-top: 12px;
    }

    .profile-modal__dark{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 9px 0;
    }

    .profile-modal__dark input{
        display: none;
    }

    .profile-modal__dark label{
        position: relative;
        width: 36px;
        height: 20px;
    }

    .profile-modal__dark label:after{
        position: absolute;
        content: '';
        width: 36px;
        height: 20px;
        background-image: url('../../assets/img/off.png');
        top: 0;
        right: 0;
    }

    .profile-modal__dark input:checked ~ label:after{
        background-image: url('../../assets/img/on.png');
    }

    li:nth-last-child(2){
        border-bottom: 2px solid #F5F6F8;
        padding-bottom: 10px;
        padding-top: 7px;
    }

    li:last-child{
        padding-top: 10px;
    }

    
    @media(max-width: 480px){
        .profile-modal{
            z-index: 111;
            right: 0px;
            top: 132px;
        }
    }

    @media(max-width: 250px){
        .profile-modal{
            width: 100%;
        }
    }

</style>