import Vue from 'vue'
import App from './App.vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import router from './router'
import './assets/css/main.css'

Vue.config.productionTip = false
Vue.config.remoteApiHost = process.env.VUE_APP_API_REMOTE_HOST 
Vue.config.authPage = process.env.VUE_APP_AUTH_PAGE 

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

new Vue({
  el: '#app',
  router,
  render: h => h(App),
  pinia,
})