import { defineStore } from 'pinia'
import Vue from 'vue'
import { useApiCallStore } from '@/store/ApiCallStore'
import { useProfileStore } from '@/store/ProfileStore'
import { ApiCall } from '@/store/ApiCall'

export const useWorkspaceStore = defineStore({
    id: 'WorkspaceStore',
    state() {
        const apiCallStore = useApiCallStore()
        const profileStore = useProfileStore()
        return {
            profileStore:profileStore,
            apiCallStore: apiCallStore,
            list: [],
            search: '',
            errors: [],
            currentWorkspace: null
        }
    },
    getters:{
        count: function(state){
            return state.list.length
        },
        workspaces: function(state){
            return state.list
        },
        current: function(state){
            return state.currentWorkspace
        },
        isCurrent: function(state){
            return state.currentWorkspace != null
        },
        //TODO: replace test_workspace to fix the logic around current workspace
        currentUk: function(state){
            return state.currentWorkspace ? state.currentWorkspace.uk : 'test_workspace'
        },
        currentTitle: function(state){
            return state.currentWorkspace ? state.currentWorkspace.t : ''
        },
    },
    actions:{
        setCurrentWorkspace(item, needRemoteUpdate = true){
            this.$patch((state) => {
                state.currentWorkspace = item
                ApiCall.workspaceUk = state.currentWorkspace.uk
                if ( needRemoteUpdate)
                    this.updateCurrentWorkspace(item)
            })
        },
        setFirstWorkspaceAsCurrent(){
            this.$patch((state) => {
                if ( state.list.length > 0 )
                    state.currentWorkspace = state.list[0]
                    ApiCall.setWorkspaceUk(state.currentWorkspace.uk)
            })
        },
        
        async updateCurrentWorkspace(workspace){
            return this.apiCallStore.apiCallAsync(
                async function(){
                    const response = await ApiCall.post( 'ws/' +workspace.uk + '/current' )
                    if ( response.data.result != 'ok') {
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    }
                    return response
                }
            )
        },
        
        async getWorkspaceDetails(uk){
            let response = await this.apiCallStore.apiCallAsync(
                async function(){
                    return await ApiCall.get( 'ws/' + uk )
                }
            )
            
            this.$patch((state) => {
                state.currentWorkspace = response.data.message
                ApiCall.setWorkspaceUk(state.currentWorkspace.uk)
            })
        },
        async listing(updateMethod = null){
            await this.apiCallStore.apiCallAsync( this.listingMethod )
            if ( updateMethod ) 
                this.$patch((state) => {
                    updateMethod(state.currentWorkspace)
                })
        },
        async listingMethod(){
            try  {
                const response = await ApiCall.get('ws');
                
                if ( response.data.result=='ok') {
                    this.$patch((state) => {
                        state.list = response.data.message
                        if ( !state.currentWorkspace && state.list.length ) {
                            if ( state.profileStore.currentUser ) {
                                for(let i = 0; i < state.list.length; i++){
                                    if ( state.list[i].uk == state.profileStore.currentUser.w_uk) {
                                        state.currentWorkspace = state.list[i]
                                        ApiCall.setWorkspaceUk(state.currentWorkspace.uk)
                                        break
                                    }
                                }
                            } else {
                                state.currentWorkspace = state.list[0]
                                ApiCall.setWorkspaceUk(state.currentWorkspace.uk)
                            }
                        }
                        if ( state.currentWorkspace == null && state.list.length > 0) {
                             this.setFirstWorkspaceAsCurrent()
                        }
                    })
                }
                else {
                    this.$patch((state) => {
                        state.errors.push(response.data.message);
                    })
                }
            }
            catch (error) {
                this.$patch((state) => {
                    state.errors.push(error);
                })
            }
        },
        async insert(dataInsert) {
            return await this.apiCallStore.apiCallAsync( this.insertMethod(dataInsert) )
        },
        async insertMethod(dataInsert){
            console.log('Insert Method: ' + dataInsert.title)
            const response = await ApiCall.post(
                'ws',
                dataInsert
            )
            this.$patch((state) => {
                state.list.push(response.data.message)
            })
            return response
        },
        async update(workspace, form){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText == 'OK') {
                console.log('Update workspace: ' + workspace.uk)
                const response = await ApiCall.post(
                    'ws/' +workspace.uk,
                    form
                )
                for(let i=0;i<this.list.length;i++){
                    if ( this.list[i].uk == workspace.uk) {
                        this.$patch((state) => {
                            state.list.splice(i, 1) 
                            state.list.splice(i, 0, response.data.message)
                        })
                        break
                    }
                }
                return response
            }
        },
        async remove(workspace) {
            return await this.apiCallStore.apiCallAsync( this.removeMethod(workspace) )
        },
        async removeMethod(workspace) {
            ApiCall.delete('ws/' + workspace.uk)
            .then(response => {
                if ( response.data.result!='ok')
                    this.$patch((state) => {
                        state.errors.push(response.data.message);
                    })

                for(let i=0;i<this.list.length;i++){
                    if ( this.list[i].uk == workspace.uk) {
                        this.list.splice(i, 1);  
                        break
                    }
                }
            })
            .catch(e => {
                this.errors.push(e)
            })
        }
    }
})