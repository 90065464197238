<template>
    <div class="teammates-modal" v-click-outside="onClickOutside">
        <input type="text" placeholder="Search Teammates">
        <ul>
            <li class="active"> <span> Anastasia F.</span> </li>
            <li class="online"> <span> Maria Do.</span> </li>
            <li class="online"> <span> Michael T.</span> </li>
            <li> <span> Maria Do.</span> </li>
            <li> <span> Anastasia F.</span> </li>
            <li class="online"> <span> Anastasia F.</span> </li>
        </ul>
    </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    
    export default {
        directives: {
        clickOutside: vClickOutside.directive
        },
        methods: {
        onClickOutside () {
            this.$emit('close')
        }
        }
    };
</script>

<style scoped>
    .teammates-modal{
        position: absolute;
        width: 300px;
        height: 257px;
        background: #fff;
        border: 1px solid #F6F7F9;
        box-sizing: border-box;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
        right: 142px;
        top: 150px;
        padding: 10px 20px;
        z-index: 11;
    }

    input{
        width: 100%;
        height: 42px;
        margin-bottom: 15px;
        border: 1px solid #EAEEF1;
        font-family: 'Montserrat', sans-serif;
        border-radius: 39px;
        padding: 0 20px;
        box-sizing: border-box;
        outline: none;
    }

    ul{
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li{
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        padding-bottom: 10px;
        cursor: pointer;
    }

    li.active span:after{
        position: absolute;
        content: '';
        color: #37BB5C;
        background-image: url('../../assets/img/active.png');
        background-repeat: no-repeat;
        background-position: right;
        width: 30px;
        height: 18px;
    }

    li.online span:after{
        position: absolute;
        content: '';
        color: #37BB5C;
        background-image: url('../../assets/img/dot.png');
        background-repeat: no-repeat;
        background-position: right;
        width: 30px;
        height: 18px;
    }

    @media(max-width:970px){
        .teammates-modal{
            z-index: 111;
            left: 150px;
            top: 185px;
        }
    }

        
    @media(max-width:800px){
        .teammates-modal{
            z-index: 11;
            left: 150px;
            top: 185px;
        }
    }

    @media(max-width: 480px){
        .teammates-modal{
            left: 60px;
            top: 185px;
        }
    }

    @media(max-width: 450px){
        .teammates-modal{
            top: 235px;
        }
    }

    @media(max-width: 360px){
        .teammates-modal{
            width: calc(100% - 60px);
        }
    }

    @media(max-width: 350px){
        .teammates-modal{
            top: 282px;
        }
    }

</style>